import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card, Segment, Header } from 'semantic-ui-react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { ErrorSegment, Package } from '..';
import request from '../../api';
import { Styles } from '../../res';

const { f_16_700_text } = Styles;

const CertPackagesList = ({ id, isCert }) => {
    const { t } = useTranslation();
    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const getPackagesList = async () => {
            setLoading(true);
            try {
                const { data: { packages } } = await request.get(`/supscription/packages?${isCert ? 'certificateId' : 'courseId'}=${id}`);
                setPackages(packages);
            } catch (error) {
                setError(error);
            }
            finally {
                setLoading(false);
            }
        };
        getPackagesList();
    }, [id, setPackages, setLoading, setError, isCert]);

    if (error) return <ErrorSegment />;
    if (!packages.length) return <Segment basic textAlign='center' loading={loading}><ErrorSegment icon='wait' text={i18next.t('ComingSoon')} /></Segment>;


    return (
        <Segment basic loading={loading}>
            <Header style={f_16_700_text}>{t('Packages')}</Header>
            <Card.Group itemsPerRow='2' stackable>
                {
                    _.orderBy(packages, ['ranks'], 'asc').map(p => <Package key={p.id} data={p} />)
                }
            </Card.Group>
        </Segment>
    );
};

export default CertPackagesList;
