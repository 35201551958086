import React from 'react';
import { Segment, Statistic } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { Consts } from '../../../res';

const ExamStats = ({ showNavStats, questions, t }) => {

    if (!showNavStats) return null;
    const flagged = questions.reduce((acc, q) => q.isFlagged + acc, 0) || 0;
    const solved = questions.reduce((acc, q) => !!(q.questionTypeId === Consts.QUESTIONS_TYPES.MCQS ? q.answerOptionId : q.answerText) + acc, 0) || 0;

    return (
        <Segment basic inverted>
            <Statistic.Group widths='4' size='mini'  >
                <Statistic label={t('Questions')} value={questions.length} color='grey' inverted />
                <Statistic label={t('Solved')} value={solved} color='green' inverted />
                <Statistic label={t('Remaning')} value={questions.length - solved} color='yellow' inverted />
                <Statistic label={t('Flagged')} value={flagged} color='blue' inverted />
            </Statistic.Group>
        </Segment>
    );
};

export default withTranslation()(ExamStats)