import i18next from 'i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
import { signout } from '../actions';
import { store } from '../store';
import history from '../history';
import PATHS from '../Routers/PATHS';
import { savePath } from '../helpers/savePath';


const { dispatch, getState } = store;

export const BASE_URL = process.env.REACT_APP_BASE_URL;
// lcp --proxyUrl https://learning-go.dev --origin http://localhost:3000 --credentials

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true
});

const toastOptions = () => {
    const isArabic = i18next.language === 'ar';
    return {
        position: isArabic ? 'bottom-left' : 'bottom-right',
        theme: 'dark',
    };
};

axiosInstance.interceptors.request.use(
    (request) => {
        const isAdmin = getState().auth?.user?.isAdmin;
        if (isAdmin) {
            if (request.method === 'post' || request.method === 'put' || request.method === 'delete') {
                const toastId = toast.loading(i18next.t("PleaseWait"), toastOptions());
                request['toastId'] = toastId;
            }
        }
        return request;
    },
    undefined
);

axiosInstance.interceptors.response.use(
    (response) => {
        const isAdmin = getState().auth?.user?.isAdmin;
        if (isAdmin) {
            if (response.config.method === 'post' || response.config.method === 'put' || response.config.method === 'delete') {
                toast.update(response.config.toastId, { render: i18next.t('Success'), type: "success", isLoading: false, autoClose: 2000, closeButton: true, ...toastOptions() });
            }
        }
        return response;
    },
    async (err) => {
        const user = getState().auth?.user;
        const isAdmin = user?.isAdmin;
        if (isAdmin) {
            if (err.response.status === 401 && err.response?.config?.url !== '/auth/login') {
                return signout()(dispatch);
            }
            if (err.response?.data?.code) {
                toast.update(err.config.toastId, { render: i18next.t(err.response.data.code), type: "error", isLoading: false, autoClose: 5000, closeButton: true, ...toastOptions() });
                return new Error(i18next.t(err.response.data.code));
            } else toast.update(err.config.toastId, { render: i18next.t("ErrorBody"), type: "error", isLoading: false, autoClose: 5000, closeButton: true, ...toastOptions() });
        } else {
            if (err.response.status === 401 && err.response?.config?.url !== '/auth/login') {
                savePath("beforeLogoutPathPref");
                await signout()(dispatch);
                return history.replace(PATHS.LANDING_SIGNIN.URL);
            }
            if (err.response?.config?.url !== '/public/log')
                axiosInstance.post('/public/log',
                    { type: "CLIENT_ERROR", message: err?.message, userId: user?.id, moreInfo: { req: err?.request, res: err?.response }, pageUrl: window.location.pathname },
                    { headers: { "Log-Auth": (new Date().getUTCHours() * 19) ^ 19930727 } });
        }
        return Promise.reject(err);
    }
);

export default axiosInstance;