export function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}

export const replaceFontFamily = (text) => {
    return text.replace(/font-family\s*:\s*[a-zA-Z\-,\s]+;/g, 'font-family: cairo;')
        .replace(/background-color:\s*[^;]+;/gi, 'background-color: none;')
        .replace(/font-size:\s*[\d.]+(px|em|%|rem|vh|vw|vmin|vmax|cm|mm|in|pt|pc|ex|ch)/g, "font-size: 14px");
};

export const swapArrayLocs = (arr, index1, index2) => {
    const tarr = [...arr];
    const temp = tarr[index1];
    tarr[index1] = tarr[index2];
    tarr[index2] = temp;
    return tarr;
};


export function deleteChoices(choices, correctChoiceId, choicesToDelete) {
    let correctIndex = choices.findIndex(choice => choice.id === correctChoiceId);
    if (correctIndex === -1) {
        return choices;
    }

    let indicesToDelete = [];

    while (indicesToDelete.length < choicesToDelete) {
        let randomIndex = Math.floor(Math.random() * choices.length);
        if (randomIndex !== correctIndex && !indicesToDelete.includes(randomIndex)) {
            indicesToDelete.push(randomIndex);
        }
    }

    indicesToDelete.sort((a, b) => b - a);

    indicesToDelete.forEach(index => {
        choices.splice(index, 1);
    });

    return choices;
}