import React, { useEffect, useState } from 'react';
import { Header, Icon, Label, Segment } from 'semantic-ui-react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { ErrorSegment } from '..';
import request from '../../api';
import { Theme, Styles } from '../../res';

const { f_16_700_text } = Styles;

const CertCoursesList = ({ certId, selectedCourse, setSelctedCourse }) => {
    const { t } = useTranslation();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const getCoursesList = async () => {
            setLoading(true);
            try {
                const { data: { courses } } = await request(`/courses/certificate/${certId}`);
                setCourses(courses);
                courses.length && setSelctedCourse(courses[0].id);
            } catch (error) {
                setError(error);
            }
            finally {
                setLoading(false);
            }
        };
        getCoursesList();
        //eslint-disable-next-line
    }, [certId, setCourses, setLoading, setError]);

    if (error) return <ErrorSegment />;
    if (!courses.length) return <Segment basic textAlign='center' loading={loading}><ErrorSegment icon='wait' text={i18next.t('ComingSoon')} /></Segment>;

    return (
        <Segment basic loading={loading}>
            <Header style={f_16_700_text}>{t('SelectCourse')}</Header>
            <Label.Group>
                {
                    courses.map(c => <Label
                        as='a'
                        key={c.id}
                        style={{ color: Theme.colors.WHITE, backgroundColor: selectedCourse === c.id ? Theme.colors.PRIMARY_50 : Theme.colors.GREY_30 }}
                        onClick={() => setSelctedCourse(c.id)}
                    >
                        {selectedCourse === c.id && <Icon name='check circle' />}
                        {c.name}
                    </Label>)
                }
            </Label.Group>
        </Segment>
    );
};

export default CertCoursesList;
